




















import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';

export default Vue.extend({
  name: 'AdminReports',
  components: {
    AppMain,
  },
  data() {
    return {
      reports: [
        { title: 'Payroll', name: 'admin-reports-payroll' },
        { title: 'Detention', name: 'admin-reports-detention' },
      ],
    };
  },
  computed: {
    isRun(): boolean {
      const hasValue = (value: unknown) => !!String(value);
      const hasQueries = Object.values(this.$route.query).some(hasValue);
      return hasQueries;
    },
  },
  methods: {
    goToReport(name: string) {
      this.$router.replace({ name });
    },
  },
});
